import {parameterize} from './utils'
import type {ModelUrlable, PathFunction} from './types'

/**
 * Returns a relative URL to the GitHub Models catalog, optionally filtered to show specific results.
 */
export function modelsCatalogPath({category, task}: {category?: string | null; task?: string | null} = {}) {
  if (!category && !task) return '/marketplace/models/catalog'

  const params = new URLSearchParams()
  params.append('type', 'models')
  if (category) params.append('category', category)
  if (task) params.append('task', parameterize(task))
  return `/marketplace?${params.toString()}`
}

/**
 * Returns a relative URL to view the details page of a GitHub Models model.
 * Keep in sync with the `marketplace_model_path` route helper in Rails.
 */
export const modelPath: PathFunction<ModelUrlable> = ({registry, name}) => `/marketplace/models/${registry}/${name}`

/**
 * Returns a relative URL to the Playground page of a GitHub Models model.
 * Keep in sync with the `marketplace_model_playground_path` route helper in Rails.
 */
export const modelPlaygroundPath: PathFunction<ModelUrlable> = model => `${modelPath(model)}/playground`

/**
 * Returns a relative URL to the endpoint for submitting feedback about a GitHub Models model.
 * Keep in sync with the `marketplace_model_feedback_path` route helper in Rails.
 */
export const modelFeedbackPath: PathFunction<ModelUrlable> = model => `${modelPath(model)}/feedback`

/**
 * Returns a relative URL to the prompt editor for a GitHub Models model.
 * Keep in sync with the `marketplace_model_prompt_path` route helper in Rails.
 */
export const modelPromptPath: PathFunction<ModelUrlable> = model => `${modelPath(model)}/prompt`

/**
 * Returns a relative URL to the evaluation page for a GitHub Models model.
 * Keep in sync with the `marketplace_model_evals_path` route helper in Rails.
 */
export const modelEvalsPath: PathFunction<ModelUrlable> = model => `${modelPath(model)}/evals`
